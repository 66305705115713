.viñedos {
    margin-top: 180px;
    width: 100vw !important;
}

.p-valores{
    transform: translate(-50%, 3%);
    font-size: 35px;
}

.none {
    display: none;
}


/*section {
    margin-top: 40px;
}*/

.row {
    margin: 0;
}

/* ---PORTADA--- */
.vid {
    width: 100%;
}

/* ---SOMOS--- */

.star {
    color: #FFF;
    width: 35px;
}

h1 {
    color: #000;
    text-align: center;
    font-family: LEMON MILK;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 20px;
}

.row-somos { 
    align-items: center;
    margin: 50px;
    display: flex;
    justify-content: center
}

.row-nosotros {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

h3 {
    color: #000;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
}

.iconos {
    width: 8rem;
}

.p-somos {
color: #6F6F6F;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
text-align: justify; 
}

.recuadro {
    background-color: #FFF5E1;
    margin: 1rem;    
    -ms-flex: 0 0 50%;
    flex: 0 0 47%;
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center
}

.p-vision {
/*color: #6F6F6F;*/
color: #000;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: start;
}

.h4-vision {
    color: #000;
text-align: center;
font-family: Poppins;
font-size: 38px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.row-mision {
    /*margin: 50px;*/
    padding: 20px;
    justify-content: center;
}

.foto-equipo {
    width: 100%;
}

/* ---VALORES--- */
.h3valores {
    color: #000;
    text-align: center;
    font-family: LEMON MILK;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.row-valores {
    margin: 50px;
    justify-content: space-around
}

.none-value{
    display: none;
}

.valores-1 {
    background-image: url(../statics/Nosotros/compromiso.png) ;
    background-size: cover;
    width: 200px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.valores-2 {
        background-image: url(../statics/Nosotros/etica.png) ;
    background-size: cover;
    width: 200px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.valores-3 {
    background-image: url(../statics/Nosotros/responsabilidad.png) ;
    background-size: cover;
    width: 200px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.valores-4 {
        background-image: url(../statics/Nosotros/creatividad.png) ;
    background-size: cover;
    width: 200px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.valores-5 {
        background-image: url(../statics/Nosotros/equipo.png) ;
    background-size: cover;
    width: 200px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-valores {
    width: 100%;
}
.p-valores-4{
    color: #FFF;
}
.p-valores {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 116%;}

/* ---SLOGAN--- */

.viñedos {
    width: 100%;
}

.contenedor {
    position: relative;
    display: inline-block;
    text-align: right;
}

.centrado {
    position: absolute;
    justify-content: space-around;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.slogan {
    color: #FFF;
    font-family: LEMON MILK;
    font-size: 130px;
    font-style: normal;
    font-weight: 400;
    line-height: 110.059%;
    text-align: left;
    margin-right: 10px;
}

.color-slogan {
    color: #E9BA16;
}

.tamaño {
    font-size: 80px;
}

.col-6-slogan {
    display: flex;
    align-items: center;
}

/* ---CERTIFICACIONES--- */
.certificaciones {
    padding: 60px;
    align-items: center
}

.certif {
    font-size: 2.60rem;
    margin: 1rem;
}

.row-certifi {
    display: flex;
    flex-direction: row;
}

.p-acreditaciones { 
    color: #6F6F6F;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: start;
}

.card-reconocimiento {
    height: 100%;
    width: 100%;
    background: #F29930;
    flex-direction: column;
    align-items: center;
}

.list-group {
    flex-direction: column;
    align-items: center;    
}

.list-group-item {
    background: #F29930;
    font-size: 15px;
    text-align: justify;
    font-family: Poppins;
    font-style: normal; color: #FFF;
    text-align: center;border: none;
}

.linea-reconocimiento {
    width: 80%;
    background: #FFF;
}

.proceso {
    width: 600px; 
    height: 576px;
}

.foto-uvas {
    width: 100%;
}


	/* Otro Slider  */
	.slider_clientes {
		--image-size: 100px;
		padding: 10px;
		overflow: hidden;
		max-width: 1200px;
		width: 100%;
		margin: 0 auto;
		position: relative;
		display: flex;
	}
	.slider_clientes:hover div {
		animation-play-state: paused;
	}
	.slider_clientes div {
		display: flex;
		position: relative;
		animation: marquee 5s linear infinite;
		justify-content: space-around;
	}
	.slider_clientes img {
		display: block;
		min-width: var(--image-size);
		height: var(--image-size);
		margin: 0 1vw;
	}
	.slider_clientes:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: linear-gradient(to right, white, rgba(255, 255, 255, 0) 80px, rgba(255, 255, 255, 0) calc(100% - 80px), white);
	}

	.img-fluid {
		width: 40px;
	}
    
    .div-acreditaciones { 
    color: #6f6f6f;
    font-family: Poppins;
    font-weight: bolder;
    font-size: 1.7rem;
    font-weight: 600;
    text-align: center;
    margin-top: 4rem;
    margin: 10px 40px;
}
@media screen and (max-width: 1800px) {
    .viñedos{
        margin-top: 0;
    }
}
@media screen and (max-width: 1600px) {

    .viñedos{
        width: 100% !important;
        margin-top: 0;
    }

    .p-valores {
        translate: translate(-50%, -50%);
        font-size: 17px;
    }
        .slogan{
            font-size: 90px;
        }
    }


@media screen and (max-width: 1200px) {
    .p-valores{
        transform: translate(-50%, -94%);
    }

    .foto-uvas{
        display: none;
    }

    .proceso {
        height: auto;
        width: 15rem; 
        margin: 15px;
            }
        .certificaciones{
            padding: 0;
            justify-content: center;
        }

    .slogan {
        font-size: 75px;
    }
}
@media (max-width: 1000px) {
    .row-nosotros {
        flex-direction: column
    }

    .none-value{
        display: block;
    }

    .value-col{
        display: flex;
    }

    .valores-1 {
        width: 200px;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
    }

    .valores-2 {
        width: 200px;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
    }
    .valores-3 {
        width: 200px;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
    }
    .valores-4 {
        width: 200px;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
    }

    .valores-5 {
        width: 200px;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
    }

    .row-valores{
        display: flex;
    flex-direction: column;
    align-items: center;
    }

    .display{
        display: none;
    }
}

	@media (max-width: 900px) {

        .video {
            width: 100%;
        }

        .viñedos {
            margin-top: 0;
        }

        .display { 
            display: none;
        }

        .none-value {
            display: block;
        }


		.slider_clientes {
		--image-size: 50px;
		--image-size: min(max(50px, 10vw), 100px);
		}

        .div-acreditaciones{
            font-size: 15px;
        }
        .slogan {
            font-size: 55px; 
        }
        .row-somos { 
            flex-direction: column;
            margin: 10px;
        }

        .recuadro {
            flex: 0 0 80%;
        }
    
        .row-mision{
            margin: 10px;
        }

    .tamaño {
        font-size: 30px;
    }

    .h3valores{
        font-size: 2rem;
    }

    h3{
        font-size: 1rem;
    }

    .col-6 {
        max-width: 100%;
    }

    .h4-vision{
        font-size: 1.5rem;
    }

    .p-vision{
        font-size: 1rem;
        text-align: start;
        overflow-y: auto; 
        max-height: 180px; 
    }

    /*Certificaciones*/
    .foto-uvas{
        display: none;
    }

        .proceso {
    height: auto;
    width: 15rem; 
    margin: 15px;
        }
    .certificaciones{
        padding: 0;
        justify-content: center;
    }

    .certif{
        font-size: 1.5rem;
    }

    .col-7 {
        max-width: 100%;
    }

    .col-5 {
        max-width: 60%;
        margin-top: 10px;
    }

    .row-certifi{
        flex-direction: column;
        align-items: center;
    }

    .mobi-value{
        display: flex;
    }

    /*Valores*/
    .valores{
        margin: 0;
    }

    .p-valores{
        transform: translate(-50%, 36%);
        height: auto;
    }}
    @media screen and (max-width: 768px) {
        .h3valores {
            font-size: 30px;
        }

        .dos {
            display: flex;
        }
    
        .valores-1 {
            width: 100px;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;
        }
    
        .valores-2 {
            width: 100px;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;
        }
        .valores-3 {
            width: 100px;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;
        }
        .valores-4 {
            width: 100px;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;
        }
    
        .valores-5 {
            width: 100px;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;
        }
    
        .valores {
            width: 120px;
            height: auto;
            margin: 15px;
        }
    
        .p-valores {
            font-size: 14px;
            transform: translate(-50%, -45%);
        }

        .slogan{
            font-size: 25px;
        }
    }
    
    @media screen and (max-width: 480px) {    
        .row-valores {
            margin: 0 10px;
        }
    
        .valores {
            width: 100%;
            height: auto;
            margin: 10px 0;
        }
    
        .p-valores {
            font-size: 12px;
        }
    }


	
	@keyframes marquee {
		0% {
		transform: translateX(0%);
		}
		100% {
		transform: translateX(-100%);
		}
	}
