.presentaciones {
    display: flex;
    justify-content: center;
}

.carrusel {
    width: 20%;
    margin: 30px;
}
.carousel-indicators {
    display: none;
  }

  .carrusel-mobi{
    display: flex;
    flex-direction: row;
  }

  .d-block {
    width: 150px;
  }

.epigrafe {
    font-size: 15px;
    text-align: center;
}
.carousel-control-next, .carousel-control-prev {
  display: none;
}

@media only screen and (max-width: 900px){
  .epigrafe {
    font-size: 14px;
}

.presentaciones {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.carrusel {
  width: 50%;
  margin: 30px;
}
.carrusel-mobi {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
}