.div-logo {
	display: flex;
	justify-content: center;
	padding: 0;
}


.col-8 {
	display: flex;
	align-items: center;
	justify-content: flex-end;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 90%;
		max-width: 90%;
}


.navbar-light .navbar-toggler {
	border-color: #E96E16;
	border-width: 2px;
}

.navbar-expand-lg .navbar-collapse {
	justify-content: end;
	margin-right: 2rem;
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 0.5rem;
}

.dropdown, .dropup {
	margin: 1rems;
}

.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23E96E16' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  

.row-nav {
    display: flex;
    justify-content: space-around;
    list-style: none;
	align-items: center;
	background: #FFF5E1; 
	width: 100%;
	padding: 0;
}

header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
}

a:hover {
	color: #424242;
}

.me-auto {
	text-align: center;
	font-family: LEMON MILK;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	align-items: center;
}

.nav-link {
	background: #FFF5E1;
	color: #E96E16 !important;
	text-decoration: none !important;
	text-align: center;
	font-family: LEMON MILK;
	font-size: 25px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding: 0;
	margin: 0;
}

a {
	margin-bottom: 8px;
}

.banderas {
	width: 30px;
	vertical-align: text-bottom;
	padding: 5px;
}

button {
	border: none;
	background-color: #FFF5E1;
	color: #E96E16;
	border-color: #fff;
    font-size: 25px;
    margin-left: 2px;
}

@media only screen and (max-width: 1200px) {
	.me-auto {
		font-size: 0;
}
}

@media (max-width: 900px) {

	.logo {
		width: 120px;
		padding: 5px;
	}

	.div-logo{
		
		justify-content: flex-start;
	}

	.row-nav{
		justify-content: flex-start;
	}

	.nav-res{
		justify-content: flex-start;
	}

	.me-auto{
		align-items: flex-start;
	}

	header{
		width: 100vw;
	}

	.col-4 {
		max-width: 100%;
        flex: 0 0 75%;
	}
}
