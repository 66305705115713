
.float{
    bottom:40px;
	height:50px;
    position:fixed;
	right:40px;
    width:50px;
    z-index:100;
}

@media only screen and (max-width: 900px){
    .float{
        right:25px;
        height:45px;
        width:45px;
    }
}





