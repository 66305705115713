@font-face {
    font-family: lemon milk;
    src: url(LEMONMILK-Regular.otf);
}

.deploy { 
    width: 100px;
}

footer {    
    background: #FFF5E1;
    color: #E96E16;
    min-height: 100vh; 
    position: relative; 
}

.ig {
    width: 55px;
    height: 55px;
    flex-shrink: 0;
}

.face {
    width: 55px;
    height: 55px;
    flex-shrink: 0;
}

.a {
    margin: 10px;
}

.linea {
    width: 217px;
    height: 1px;
    background: #E96E16;
    margin: 8px 0 17px 0px;
}

.icon {
    font-size: 30px;
   vertical-align: middle;
}

h5 {
    color: #E96E16;
    text-align: center;
    font-family: lemon milk;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.text-center {
    font-family: 'Poppins';
    font-size: 16px;
    background-color: #424242;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    padding: 0;
}

.ubi {
    margin-top: 82px;
}

p {
    color: #E96E16;
    text-align: center;
    font-family: 'Poppins';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.p-ubicacion {
    text-align: start;
}

.a-contacto {
    color: #E96E16;
text-align: center;
font-family: 'Poppins';
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal
} 

.frutandinalogoBlanco  {
    width: 203px;
    height: 73px;
    flex-shrink: 0;
    margin:  45px 0;
}

.col-footer {
    margin-left: 110px;
}

.col-6-footer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
}

.cajafooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
@media (max-width: 1120px) {
    .text-center{
        position: relative;
    }
}

@media (max-width: 1200px) {
    footer{
        width: 100vw;
    }

    .col-footer {
        margin-left: 30px;
    }

    .list-group-item {
        font-size: 12px;
    }
}

@media (max-width: 900px) {
    .col-footer{
        margin-left: 20px;
    }

    .row-col-4 {
        justify-content: center;
    }
}

@media only screen and (max-width: 768px) {
    footer{
        width: 100vw;
    }

    .deploy {
        width: 80px; 
    }

    .ig,
    .face {
        width: 40px; 
        height: 40px; 
        margin: 30px 0; 
    }

    .ubi{
        display: flex;
        margin-top: 40px;
    }

    .none {
        display: none;
    }


    .cajafooter{
        margin-top: 20px;
    }

    .frutandinalogoBlanco {
        display: none;
    }

    .col-footer {
        margin-left: 0;
    }

    .col-6-footer {
        margin-top: 20px;
        align-items: center;
    }

    .p-3{
        bottom: 0;
        text-align: center;
        padding: 10px;
        width: 100vw;
    }
}


