.cajas1 {
    display: flex;
    justify-content: center;
    margin: 15px;
    border: #f5f5f5;
}

.row-produccion {
    align-items: center;
    justify-content: space-around;
    margin: 40px;
}

.total {
  display: flex;
  justify-content: center !important;
  max-width: 45.333333% !important;
}

.total-1 {
  display: flex;
  justify-content: flex-end ;
  max-width: 45.333333% !important;
}


.margin {
  margin-bottom: 30px;
}

.pasas {
    border-radius:50%; 
    width:100%;     
}

.contenedorPtos {
  position: relative;
  width: 300px;
  margin: 30px ;
}

.caja {
  position: relative;
  width: 150px;
  height: 200px;
  /*overflow: hidden;*/
  margin: 0 auto;
 
}

.imagen-agrandada-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.contenedorPtos:hover .imagen-circular img {
  transform: scale(1.2); 
}

.imagen-agrandada-overlay img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 0; 
}


.imagen-circular {
  position: relative;
  z-index: 1;
}

.imagen-circular img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 15px 25px rgba(0,0,0,.2);
  
}

.fondo {
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: 4rem; 
    background-color: #E96E16; 
    }

.texto {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 100%;
    height: 50%;
    transform: translateX(-50%);
    color: #000; 
    text-align: center;
    background-color:  #FFDAA5;
}

.p-caja {
    color: #424242;
    margin-top: 3rem;
    font-size: 15px;
    font-weight: 500;
  }

  .p-produccion {
    color: #6F6F6F;
font-family: Inter;
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: start;
}

  @media only screen and (max-width: 1200px) {
    .row-produccion{
      display: flex;
      flex-direction: column;
    }
    .total{
      max-width: 80% !important;
      flex: 0 0 80%;
      display: flex;
    justify-content: center;
    margin-top: 25px;
    }

    .total-1{
      max-width: 80% !important;
      flex: 0 0 80%;
      display: flex;
    justify-content: center !important;
    margin-top: 25px;
    }

    .row-produccion{
      margin: 40px 10px;
    }
.p-produccion{
      font-size: 1rem;
      text-align: start;
  }
}

    @media only screen and (max-width: 768px) {
    .row-produccion, .contenedorPtos, .caja {
          width: 100%;
          margin: 10px;
      }

      .fondo {
        height: 5rem;
      }
      .p-caja {
        color: #424242;
        margin-top: 3.5rem;}
        
      .cajas1 {
        flex-direction: column !important;
        align-items: center;
      }

      .imagen-circular img {
        width: 21%;
    }


    .imagen-circular{
      text-align: center; 
    }

      h1 {
        font-size: 25px;
      }

      .efigrafe{
        font-size: 10px;
      }

      .total{
        max-width: 100%;
        flex: 0 0 100%;
      }

      .total-1{
        max-width: 100%;
        flex: 0 0 100%;
      }

      .video-produccion{
        width: auto !important ;
        height: auto !important;
      }

      form {
        display: none;
      }

      @media only screen and (max-width: 600px) {
        .imagen-circular img {
          width: 30%;
        }
        }

      @media only screen and (max-width: 400px) {
      .imagen-circular img {
        width: 50%;   
      }
      }
  }