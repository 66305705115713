.formulario { 
    margin-top: 62px;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

input {
    width: 407px;
    height: 35.288px;
    flex-shrink: 0;
    border-radius: 7.017px;
    border: 0;
    background: #fff;
    margin: 11px;
    padding-left: 15px;
    color: #898989;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

textarea {
    width: 407px;
    height: 241.504px;
    flex-shrink: 0;
    border: 0;
    border-radius: 7.017px;
    /*background: #EDECEC;*/
    background: #fff;
    margin: 11px;
    padding-left: 15px;
    color: #898989;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    resize: none;
}

.enviar {
    width: 143.853px;
height: 40.802px;
flex-shrink: 0;
border-radius: 7.017px;
background: #482809;
text-align: center;
color: #FDFDFD;}

.enviado {
    background-color: #43a047;
    color: E96E16;
    font-size: 30px;
    font-weight: bold;
}

.divEnviar{
    display: flex;
}

.requerido {
    color: #E96E16;
    text-align: start;
    margin-bottom: 0;
}

@media only screen and (max-width: 800px) {
    input,
    textarea{
        width: 50vw;
    }

    .row-col-4 {
        justify-content: center;
    }
}

@media only screen and (max-width: 600px) {
    form {
        width: 90vw; 
    }

    input,
    textarea,
    .enviar {
        width: 100%;
        max-width: 100%; /* Ancho completo en pantallas más pequeñas */
    }
}